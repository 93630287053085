import { Users } from "./data/users";
import { User } from "./user";
import { parseTime, parseAttributes } from "./util";
import { Logger } from "./logger";
import { Conversation } from "./conversation";
import { json } from "./interfaces/rules";
import { validateTypesAsync } from "@twilio/declarative-type-validator";
import { CommandExecutor } from "./command-executor";
import { EditParticipantRequest } from "./interfaces/commands/edit-participant";
import { ParticipantResponse } from "./interfaces/commands/participant-response";
import { ReplayEventEmitter } from "@twilio/replay-event-emitter";
import isEqual from "lodash.isequal";
import { JSONValue } from "./types";

type ParticipantEvents = {
  typingEnded: (participant: Participant) => void;
  typingStarted: (participant: Participant) => void;
  updated: (data: {
    participant: Participant;
    updateReasons: ParticipantUpdateReason[];
  }) => void;
};

const log = Logger.scope("Participant");

interface ParticipantDescriptor {
  attributes?: JSONValue;
  dateCreated: Date | null;
  dateUpdated: Date | null;
  identity: string;
  roleSid?: string;
  lastConsumedMessageIndex: number | null;
  lastConsumptionTimestamp: number | null;
  type: ParticipantType;
  userInfo?: string;
  bindings?: ParticipantBindings;
}

interface ParticipantState {
  attributes: JSONValue;
  dateCreated: Date | null;
  dateUpdated: Date | null;
  identity: string;
  isTyping: boolean;
  lastReadMessageIndex: number | null;
  lastReadTimestamp: Date | null;
  roleSid: string;
  sid: string;
  type: ParticipantType;
  typingTimeout: number | null;
  userInfo?: string;
  bindings?: ParticipantBindings;
}

interface ParticipantServices {
  users: Users;
  commandExecutor: CommandExecutor;
}

interface ParticipantLinks {
  self: string;
}

/**
 * The reason for the `updated` event being emitted by a participant.
 */
type ParticipantUpdateReason =
  | "attributes"
  | "dateCreated"
  | "dateUpdated"
  | "roleSid"
  | "lastReadMessageIndex"
  | "lastReadTimestamp"
  | "bindings";

/**
 * Participant type. The string variant can be used to denote new types of
 * participant that aren't supported by this version of the SDK.
 */
type ParticipantType = "chat" | "sms" | "whatsapp" | "email" | string;

interface ParticipantUpdatedEventArgs {
  participant: Participant;
  updateReasons: ParticipantUpdateReason[];
}

/**
 * Bindings for conversation participant.
 */
interface ParticipantBindings {
  email?: ParticipantEmailBinding;
}

/**
 * Email participation level.
 * to = to/from
 * cc = cc
 */
type ParticipantEmailLevel = "to" | "cc";

/**
 * Bindings for email participant.
 */
interface ParticipantEmailBinding {
  name: string;
  address: string;
  level: ParticipantEmailLevel;
}

/**
 * A participant represents a remote client in a conversation.
 */
class Participant extends ReplayEventEmitter<ParticipantEvents> {
  private state: ParticipantState;
  private readonly links: ParticipantLinks;
  private readonly services: ParticipantServices;

  /**
   * Conversation that the remote client is a participant of.
   */
  public readonly conversation: Conversation;

  /**
   * The server-assigned unique identifier for the participant.
   */
  public get sid(): string {
    return this.state.sid;
  }

  /**
   * Custom attributes of the participant.
   */
  public get attributes(): JSONValue {
    return this.state.attributes;
  }

  /**
   * Date this participant was created on.
   */
  public get dateCreated(): Date | null {
    return this.state.dateCreated;
  }

  /**
   * Date this participant was last updated on.
   */
  public get dateUpdated(): Date | null {
    return this.state.dateUpdated;
  }

  /**
   * Identity of the participant.
   */
  public get identity(): string | null {
    return this.state.identity;
  }

  /**
   * Indicates whether the participant is currently typing.
   */
  public get isTyping(): boolean {
    return this.state.isTyping;
  }

  /**
   * The index of the last read message by the participant.
   * Note that retrieving messages on a client endpoint does not mean that messages are read,
   * please consider reading about the [Read Horizon feature](https://www.twilio.com/docs/api/chat/guides/consumption-horizon)
   * to find out about the proper way to mark messages as read.
   */
  public get lastReadMessageIndex(): number | null {
    return this.state.lastReadMessageIndex;
  }

  /**
   * Date of the most recent read horizon update.
   */
  public get lastReadTimestamp(): Date | null {
    return this.state.lastReadTimestamp;
  }

  public get roleSid(): string {
    return this.state.roleSid;
  }

  /**
   * Type of the participant.
   */
  public get type(): ParticipantType {
    return this.state.type;
  }

  /**
   * Get the bindings mapping for the current participant.
   * Available binding depends on the participant type.
   * You could access it as `participant.bindings.sms?.address` or
   * using the type dynamically `participant.bindings[participant.type]`
   * just be aware that the binding information has different structure for
   * each participant type.
   * See also {ParticipantEmailBinding}, the only available currently binding descriptor.
   */
  public get bindings(): ParticipantBindings {
    return this.state.bindings ?? {};
  }

  /**
   * @internal
   */
  constructor(
    data: ParticipantDescriptor,
    sid: string,
    conversation: Conversation,
    links: ParticipantLinks,
    services: ParticipantServices
  ) {
    super();

    this.conversation = conversation;
    this.links = links;
    this.services = services;
    this.state = {
      attributes: parseAttributes(
        data.attributes,
        "Retrieved malformed attributes from the server for participant: " +
          sid,
        log
      ),
      dateCreated: data.dateCreated ? parseTime(data.dateCreated) : null,
      dateUpdated: data.dateCreated ? parseTime(data.dateUpdated) : null,
      sid: sid,
      typingTimeout: null,
      isTyping: false,
      identity: data.identity,
      roleSid: data.roleSid ?? "",
      lastReadMessageIndex: Number.isInteger(data.lastConsumedMessageIndex)
        ? data.lastConsumedMessageIndex
        : null,
      lastReadTimestamp: data.lastConsumptionTimestamp
        ? parseTime(data.lastConsumptionTimestamp)
        : null,
      type: data.type || "chat",
      userInfo: data.userInfo,
      bindings: data.bindings ?? {},
    };

    if (!data.identity && !data.type) {
      throw new Error(
        "Received invalid Participant object from server: Missing identity or type of Participant."
      );
    }
  }

  /**
   * Fired when the participant has started typing.
   *
   * Parameters:
   * 1. {@link Participant} `participant` - the participant in question
   * @event
   */
  static readonly typingStarted = "typingStarted";

  /**
   * Fired when the participant has stopped typing.
   *
   * Parameters:
   * 1. {@link Participant} `participant` - the participant in question
   * @event
   */
  static readonly typingEnded = "typingEnded";

  /**
   * Fired when the fields of the participant have been updated.
   *
   * Parameters:
   * 1. object `data` - info object provided with the event. It has the following properties:
   *     * {@link Participant} participant - the participant in question
   *     * {@link ParticipantUpdateReason}[] updateReasons - array of reasons for the update
   * @event
   */
  static readonly updated = "updated";

  /**
   * Internal method used to start or reset the typing indicator timeout (with event emitting).
   * @internal
   */
  _startTyping(timeout) {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    this.state.isTyping = true;
    this.emit("typingStarted", this);

    this.conversation.emit("typingStarted", this);

    this.state.typingTimeout = Number(
      setTimeout(() => this._endTyping(), timeout)
    );
    return this;
  }

  /**
   * Internal method function used to stop the typing indicator timeout (with event emitting).
   * @internal
   */
  _endTyping() {
    if (!this.state.typingTimeout) {
      return;
    }

    this.state.isTyping = false;
    this.emit("typingEnded", this);

    this.conversation.emit("typingEnded", this);

    clearInterval(this.state.typingTimeout);
    this.state.typingTimeout = null;
  }

  /**
   * Internal method function used update local object's property roleSid with a new value.
   * @internal
   */
  _update(data) {
    const updateReasons: ParticipantUpdateReason[] = [];

    const updateAttributes = parseAttributes(
      data.attributes,
      "Retrieved malformed attributes from the server for participant: " +
        this.state.sid,
      log
    );

    if (data.attributes && !isEqual(this.state.attributes, updateAttributes)) {
      this.state.attributes = updateAttributes;
      updateReasons.push("attributes");
    }

    const updatedDateUpdated = parseTime(data.dateUpdated);
    if (
      data.dateUpdated &&
      updatedDateUpdated?.getTime() !==
        (this.state.dateUpdated && this.state.dateUpdated.getTime())
    ) {
      this.state.dateUpdated = updatedDateUpdated;
      updateReasons.push("dateUpdated");
    }

    const updatedDateCreated = parseTime(data.dateCreated);
    if (
      data.dateCreated &&
      updatedDateCreated?.getTime() !==
        (this.state.dateCreated && this.state.dateCreated.getTime())
    ) {
      this.state.dateCreated = updatedDateCreated;
      updateReasons.push("dateCreated");
    }

    if (data.roleSid && this.state.roleSid !== data.roleSid) {
      this.state.roleSid = data.roleSid;
      updateReasons.push("roleSid");
    }

    if (
      (Number.isInteger(data.lastConsumedMessageIndex) ||
        data.lastConsumedMessageIndex === null) &&
      this.state.lastReadMessageIndex !== data.lastConsumedMessageIndex
    ) {
      this.state.lastReadMessageIndex = data.lastConsumedMessageIndex;
      updateReasons.push("lastReadMessageIndex");
    }

    if (data.lastConsumptionTimestamp) {
      const lastReadTimestamp = new Date(data.lastConsumptionTimestamp);
      if (
        !this.state.lastReadTimestamp ||
        this.state.lastReadTimestamp.getTime() !== lastReadTimestamp.getTime()
      ) {
        this.state.lastReadTimestamp = lastReadTimestamp;
        updateReasons.push("lastReadTimestamp");
      }
    }

    if (data.bindings && !isEqual(this.state.bindings, data.bindings)) {
      this.state.bindings = data.bindings;
      updateReasons.push("bindings");
    }

    if (updateReasons.length > 0) {
      this.emit("updated", { participant: this, updateReasons: updateReasons });
    }

    return this;
  }

  /**
   * Get the user for this participant and subscribes to it. Supported only for participants of type `chat`.
   */
  async getUser(): Promise<User> {
    if (this.type != "chat") {
      throw new Error(
        "Getting User is not supported for this Participant type: " + this.type
      );
    }

    return this.services.users.getUser(
      this.state.identity,
      this.state.userInfo
    );
  }

  /**
   * Remove the participant from the conversation.
   */
  async remove() {
    return this.conversation.removeParticipant(this);
  }

  /**
   * Update the attributes of the participant.
   * @param attributes New attributes.
   */
  @validateTypesAsync(json)
  async updateAttributes(attributes: JSONValue): Promise<Participant> {
    await this.services.commandExecutor.mutateResource<
      EditParticipantRequest,
      ParticipantResponse
    >("post", this.links.self, {
      attributes: JSON.stringify(attributes),
    });

    return this;
  }
}

export {
  ParticipantDescriptor,
  ParticipantServices,
  Participant,
  ParticipantUpdateReason,
  ParticipantType,
  ParticipantUpdatedEventArgs,
  ParticipantBindings,
  ParticipantEmailBinding,
  ParticipantEmailLevel,
};
